import React from 'react';
import Result_Failed from '../../../components/Biz/Shared/Result_Failed.js';

export default function Result() {

  return (
    <>
        <div data-aos="fade-up"><Result_Failed /></div>
    </>
  );
}
